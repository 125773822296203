<template>
  <v-container class="fill-height">
    <v-row v-if="errorMsg.length != 0">
      <v-col>
        <v-alert border="left" type="error" elevation="2">
          {{ errorMsg }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="10" class="mx-auto" width="800">
          <v-app-bar color="indigo darken-2" flat dark>
            <v-toolbar-title>
              <v-icon>mdi-account-details</v-icon>Please fill in the details
              below
            </v-toolbar-title>
          </v-app-bar>

          <v-container>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="name"
                :counter="100"
                :rules="nameRules"
                label="* Full Name"
                required
              ></v-text-field>

              <v-text-field
                v-model="cid"
                :counter="13"
                :rules="cidRules"
                maxlength="13"
                label="* Reference ID"
                hint="ex. (0011241251122)"
                required
              ></v-text-field>

              <v-select
                :items="['Myanmar', 'Cambodia', 'Laos']"
                v-model="national"
                label="* Nationality"
                :rules="nameRules"
              ></v-select>

              <v-text-field
                v-model="address"
                :counter="250"
                :rules="addressRules"
                maxlength="250"
                label="* Address"
                hint
                required
              ></v-text-field>

              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                hint="ex.(0861234567)"
                :label="mobilePhoneLabel"
                maxlength="10"
                required
              >
              </v-text-field>

              <!-- <v-text-field v-model="email" :rules="emailRules" label="E-mail" ></v-text-field> -->
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              :loading="loading"
              @click="saveData"
            >
              <v-icon>mdi-save</v-icon>Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <queue-dialog
      v-model="queueDialog"
      :queueId="queueId"
      @close="closeQueueDialog"
    />
  </v-container>
</template>

<script>
import { worker_reserve } from "../api/reserve";
import queueDialog from "./components/queueConfirm";
export default {
  components: { queueDialog },
  data() {
    return {
      valid: false,
      name: "",
      nameRules: [
        v => !!v || "This field is required.",
        v => /[A-Za-zก-๙]+/.test(v) || "fill in with English or Thai only."
      ],
      cid: "",
      cidRules: [
        v => !!v || "This field is required.",
        v => /\d{13}/.test(v) || "only numeric and fill up to 13 digits."
      ],
      phone: "",
      phoneRules: [
        v => !!v || "This field is required.",
        v =>
          /\d{10}/.test(v) ||
          "only numeric (no space or - or _) ex. 0812345678."
      ],
      email: "",
      emailRules: [
        // v => !!v || "E-mail is required",
        v => (v && /.+@.+\..+/.test(v)) || "input valid email."
      ],
      national: "",
      address: "",
      addressRules: [v => !!v || "This field is required."],
      foreignworker: "",
      dataSaved: false,
      errorMsg: "",
      token: "",
      loading: false,
      mobilePhoneLabel: "*Mobile Number",
      otp: "",
      reqBtn: false,
      reqBtnLabel: "REQUEST OTP",
      queueDialog: false,
      queueId: ""
    };
  },
  methods: {
    async saveData() {
      // check for valid otp requested

      if (this.$refs.form.validate()) {
        this.loading = true;
        var data = {
          name: this.name,
          refId: this.cid,
          address: this.address,
          national: this.national,
          phone: this.phone
        };
        this.loading = true;
        worker_reserve(data)
          .then(res => {
            console.log(res);
            if (res.success) {
              this.dataSaved = true;
              this.queueId = res.queueId;
              this.queueDialog = true;
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    closeQueueDialog() {
      this.$refs.form.reset();
      this.queueId = "";
      this.queueDialog = false;
      this.workerList = [];
    }
  }
};
</script>

<style></style>
